<template>
    <div>
        <breadcrumb
            name="Test Drive"
            title="Request"
            subtitle="View"
            @onTitleClicked="goBack"
        />
        <vx-card title="Details" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table" v-if="item">
                            <tr>
                                <td class="font-semibold p-4">Name</td>
                                <td class="p-4">{{ item.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Brand Name</td>
                                <td class="p-4">
                                    {{
                                        item.parent
                                            ? item.parent.brand_name
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Branch Name</td>
                                <td class="p-4">{{ item.branch.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Date</td>
                                <td class="p-4">
                                    {{ item.date | moment("DD MMMM YYYY") }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table" v-if="item">
                            <tr>
                                <td class="font-semibold p-4">Mobile</td>
                                <td class="p-4">{{ item.mobile }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Model Name</td>
                                <td class="p-4">
                                    {{
                                        item.parent
                                            ? item.parent.model_name
                                            : "Not Set"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Category Name</td>
                                <td class="p-4">{{ item.category.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Time</td>
                                <td class="p-4">{{ item.time }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Status</td>
                                <td class="p-4">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td class="font-semibold p-4">
                                    Agent Name
                                </td>
                                <td class="p-4">
                                    <span
                                        v-if="
                                            item.agent_status &&
                                                item.agent_status.admin
                                        "
                                    >
                                        {{ item.agent_status.admin.name }}
                                    </span>
                                    <vs-td v-else>None</vs-td>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Agent Email
                                </td>
                                <td class="p-4">
                                    <span
                                        v-if="
                                            item.agent_status &&
                                                item.agent_status.admin
                                        "
                                    >
                                        {{ item.agent_status.admin.email }}
                                    </span>
                                    <vs-td v-else>None</vs-td>
                                </td>
                            </tr> -->
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card title="Update Status" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold p-4">
                                    Request Status
                                </td>
                                <td class="p-4">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                item.agent_status
                                                    ? item.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                    <feather-icon
                                        icon="EditIcon"
                                        svgClasses="w-6 mt-1 h-6 mr-2 hover:text-warning stroke-current"
                                        @click="
                                            agent_status_update =
                                                !agent_status_update
                                        "
                                        class="ml-12"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    v-if="agent_status_update"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                >
                    <label>Status</label>
                    <select v-model="agent_status">
                        <option value="pending">Pending</option>
                        <option value="in_progress">In Progress</option>
                        <option value="done">Done</option>
                    </select>
                </vs-col>
            </vs-row>
            <div class="optionBtns" v-if="agent_status_update">
                <vs-button
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="changeAgentStatus"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="agent_status_update = false"
                    >Cancel
                </vs-button>
            </div>
        </vx-card>
        <vs-button
            color="primary"
            type="filled"
            style="float: right"
            @click="goBack"
            >Back
        </vs-button>
        <div class="clr" />
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import ChooseModel from "../../../components/custom/choose-model";

export default {
    components: { ChooseModel, Breadcrumb },
    name: "view-tradeIn-request",
    data() {
        return {
            id: this.$route.params.id,
            chosenDays: [],
            branches: [],
            model_id: "",
            branch_id: "",
            start_at: "",
            end_at: "",
            item: "",
            items: [],
            modelCounter: 0,
            agent_status: "",
            agent_status_update: false,
        };
    },
    methods: {
        changeAgentStatus() {
            const payload = {
                request_id: this.item.id,
                request_type: "test_drive",
                status: this.agent_status,
            };
            console.log(this.$store.state.auth.token);
            this.$httpAdmin
                .post(`/requests/change-status`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Agent Status",
                        text: "Agent Status is changed successfully!",
                        color: "success",
                    });
                    location.reload();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getAgentStatusColor(status) {
            if (status == "pending") return "dark";
            if (status == "in_progress") return "primary";
            if (status == "done") return "success";
            return "danger";
        },
        getAgentStatusState(status) {
            if (status == "pending") return "Pending";
            if (status == "in_progress") return "In Progress";
            if (status == "done") return "Done";
            return "Unknown";
        },
        getData() {
            this.$vs.loading();
            this.$httpAdmin
                .get(`/test-drives/requests`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.items = res.data.data.data;
                    console.log({ items: this.items });
                    for (let i in this.items) {
                        if (this.items[i].id == this.id) {
                            this.item = this.items[i];
                            break;
                        }
                    }
                    this.agent_status = this.item.agent_status;
                    console.log({ item: this.item });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getDays(days) {
            let s = "";
            for (let i in days) {
                for (let x in this.days) {
                    if (this.days[x].value == days[i]) {
                        s += this.days[x].day + " ,";
                    }
                }
            }
            s = s.substring(0, s.length - 2);
            return s;
        },
        goBack() {
            this.$router.push("/test-drive");
        },
        timeConvert(time) {
            time = time
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice(1);
                time[5] = +time[0] < 12 ? " AM" : " PM";
                time[0] = +time[0] % 12 || 12;
                if (time[0] < 10) {
                    time[0] = "0" + time[0];
                }
            }
            return time.join("");
        },
        validateEmail(email) {
            let re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
            return re.test(String(email).toLowerCase());
        },
    },
    watch: {
        brand_id(value) {
            for (let i in this.brands) {
                if (this.brands[i].id === value) {
                    this.brand_name = this.brands[i].name_en;
                    break;
                }
            }
        },
    },
    computed: {
        invalidForm() {
            if (
                this.chosenDays.length !== 0 &&
                this.model_id !== "0" &&
                this.branch_id !== "0" &&
                this.start_at &&
                this.end_at
            ) {
                return false;
            }
            return true;
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.body {
    border-radius: 10px;
    padding: 20px;
}

.optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.days {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.input {
    width: 90%;
}

.emails {
    height: 175px;
}

// label {
//     display: block;
//     color: rgba(0, 0, 0, 0.6);
//     font-size: 12px;
//     margin-top: 20px;
// }

select {
    width: 90%;
    margin-left: 15px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.select {
    width: 25%;
    float: right;
    margin-bottom: 30px;
}

.body {
    background-color: #fff;
    padding-top: 20px;
}
</style>
